<template>
  <div v-if="hasTags" class="d-flex pt-2">
    <v-tooltip v-for="(tag, index) in uniqueTags" :key="index" :disabled="tag.style === 'simple'" bottom>
      <template #activator="{ on, attrs }">
        <Tag
          :color="tag.color_code"
          :progress="tag.style === 'progress' ? taggerProgressForSlug(tag.slug) : null"
          class="caption mr-1"
          data-testid="tag-list-tag"
          v-bind="attrs"
          v-on="on"
        >
          {{ tag.name }}
        </Tag>
      </template>
      <span v-if="tag.style !== 'simple'">{{ tag.name }}:{{ tagCount(tag) }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import size from 'lodash/size'
import filter from 'lodash/filter'

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    uniqueTags() {
      return uniqBy(this.tags, 'name')
    },
    hasTags() {
      return !isEmpty(this.tags)
    },
  },
  methods: {
    taggedCountForSlug(slug) {
      return size(filter(this.tags, { slug }))
    },
    taggerCountForSlug(slug) {
      return this.$store.getters['tags/getTagForSlug'](slug).tagger_count
    },
    taggerProgressForSlug(slug) {
      return (this.taggedCountForSlug(slug) / this.taggerCountForSlug(slug)) * 100
    },
    tagCount(tag) {
      switch (tag.style) {
        case 'progress':
          return this.$t('tag_field_selectable_tag', {
            selectedCount: this.taggedCountForSlug(tag.slug),
            availableCount: this.taggerCountForSlug(tag.slug),
          })
        case 'count':
          return `${this.taggedCountForSlug(tag.slug)}`
        case 'simple':
        default:
          return ''
      }
    },
  },
}
</script>
<style>
.tag-list-progress {
  max-width: 180px;
  letter-spacing: 1px !important;

  .v-progress-linear__content {
    display: block;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    left: 10px;
    right: 10px;
    text-align: center;
  }
}
</style>

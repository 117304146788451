<template>
  <v-list-item :to="layer.showURL(version)" exact :class="layerClasses" data-testid="annotation-layer-list-item">
    <v-list-item-content>
      <v-list-item-title>{{ layer.name }}</v-list-item-title>
      <template v-if="!layer.is_private_layer && showDetail">
        <v-list-item-subtitle>{{ $t('annotation_shared_by') }}: {{ layer.owner_name }}</v-list-item-subtitle>
        <v-list-item-subtitle style="white-space: normal"
          >{{ $t('annotation_shared_with') }}: {{ sharedWithUsers }}</v-list-item-subtitle
        ></template
      >
    </v-list-item-content>
    <v-list-item-action v-if="layer.is_restored">
      <v-tooltip bottom style="margin-top: -1px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            color="info"
            data-testid="restored-layer-hint"
            v-on="on"
            v-text="'mdi-restore-alert'"
          />
        </template>
        <span v-text="$t('restoredLayerHint')" />
      </v-tooltip>
    </v-list-item-action>
    <v-icon v-if="!layer.is_private_layer" @click="showAnnotationDetails">mdi-information</v-icon>
    <ListActionIndicator :item="layer" />
    <v-list-item-action v-if="showShareButton">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" :to="layer.showURL(version, true)" small text icon data-testid="share-button" v-on="on">
            <v-icon small>{{ shareButtonIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ shareButtonTooltipText }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-else-if="layer.is_legacy_layer">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" small text icon v-on="on">
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('share_tooltip_legacy') }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import path from 'path'
import filter from 'lodash/filter'
import { api } from '@/api'

export default {
  name: 'LayerListItem',
  props: {
    layer: {
      type: Object,
      required: true,
    },
    version: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: {
        type: Boolean,
        default: false,
      },
      sharedWithUsers: null,
      showDetail: false,
    }
  },
  computed: {
    layerClasses() {
      return this.$route.query.layer_id && this.layer.id.toString() === this.$route.query.layer_id.toString()
        ? ['primary--text', 'blue', 'lighten-5']
        : []
    },
    showShareButton() {
      return this.$config.features.shareAnnotations && this.layer.is_owner && !this.layer.is_legacy_layer
    },
    shareButtonIcon() {
      if (this.layer.is_private_layer) {
        return 'mdi-share-variant'
      }
      return 'mdi-pencil'
    },
    shareButtonTooltipText() {
      if (this.layer.is_private_layer) {
        return this.$t('share_tooltip_text')
      }
      return this.$t('edit_share_tooltip_text')
    },
  },
  async created() {},
  methods: {
    async showAnnotationDetails() {
      if (this.sharedWithUsers == null) {
        const { data } = await api.get(path.join('v1', this.layer.idPath))
        const selectedUsers = filter(data.users, { shared: true })
        this.sharedWithUsers = selectedUsers.map((x) => x.title).join(', ')
        if (this.sharedWithUsers === '') {
          this.sharedWithUsers = '-'
        }
      }
      this.showDetail = !this.showDetail
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g(_vm._b({staticClass:"tag d-flex align-content-center",class:{
    'tag--big': _vm.big,
    'tag--has-info': _vm.infoState !== _vm.INFO_STATE.HIDDEN,
    'tag--has-progress': _vm.progress !== null,
  },style:(_vm.cssProperties)},'div',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"tag__content rounded-xl text-center font-weight-medium align-content-center overflow-hidden",class:{
      'pr-5 pl-5': _vm.big,
      'pr-3 pl-3': !_vm.big,
      'cursor-pointer': !_vm.readonly,
    },on:{"click":function($event){!_vm.readonly && _vm.$emit('toggle')}}},[_vm._t("default")],2),(_vm.infoState !== _vm.INFO_STATE.HIDDEN)?_c('v-btn',{staticClass:"tag__info rounded-xl",class:{
      'tag__info--active': _vm.infoState === _vm.INFO_STATE.ACTIVE,
    },attrs:{"disabled":_vm.infoState === _vm.INFO_STATE.DISABLED,"value":null,"data-testid":"tag-field-reveal-tag","icon":""},on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('info')}}},[_c('v-icon',{staticClass:"h-auto w-auto",attrs:{"color":"grey","size":"32px"}},[_vm._v("mdi-information-symbol")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import BaseModel from '@/store/models/base'

export default class AnnotationLayer extends BaseModel {
  static entity = 'annotation_layer'

  static apiPath = 'annotationlayers'

  static fields() {
    return {
      id: this.attr(null),
      is_owner: this.attr(null),
      name: this.string(''),
      display_name: this.string(''),
      object_id: this.attr(null),
      content_type: this.attr(null),
      has_annotations: this.attr(null),
      updated: this.attr('', (v) => new Date(v)),
      is_legacy_layer: this.attr(null),
      is_restored: this.attr(null),
      is_private_layer: this.attr(null),
      owner_id: this.attr(null),
      owner_name: this.attr(null),
    }
  }

  showURL(version, share = false) {
    return {
      name: version.constructor.entity,
      params: { id: version.id },
      query: { version_id: version.version_id, layer_id: this.id, share },
    }
  }

  // eslint-disable-next-line camelcase
  get last_seen() {
    return true
  }
}

<template>
  <BaseLayout>
    <template v-if="business">
      <BusinessSidebar slot="drawer-left" :committee-id="business.committee.id" />
      <v-card>
        <v-card-title
          ><h1 class="heading">{{ business.number }} {{ business.title }}</h1></v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col>
              <h3 class="pa-3">{{ $t('business.attributes') }}</h3>
              <v-list class="meta-data-list">
                <div class="meta-data-item">
                  <v-list-item class="my-2">
                    <span class="font-weight-bold pr-2"> {{ $t('business.type') }}: </span
                    >{{ business.type ? business.type.title : '-' }}
                  </v-list-item>
                </div>
                <div class="meta-data-item">
                  <v-list-item class="my-2">
                    <span class="font-weight-bold pr-2"> {{ $t('business.committee') }}: </span
                    >{{ business.committee ? business.committee.title : '-' }}
                  </v-list-item>
                </div>
                <div class="meta-data-item">
                  <v-list-item class="my-2">
                    <span class="font-weight-bold pr-2"> {{ $t('business.sourceCommittee') }}: </span
                    >{{ business.source_committee ? business.source_committee.title : '-' }}
                  </v-list-item>
                </div>
              </v-list>
            </v-col>
            <v-col>
              <h3 class="pa-3">{{ $t('business.meetings') }}</h3>
              <v-list two-line>
                <template v-if="agenda_items.length">
                  <v-list-item v-for="agenda_item in agenda_items" :key="agenda_item.id">
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap text-break">
                        <RouterLink
                          :to="{ name: 'meeting', params: { id: agenda_item.meeting.id } }"
                          dense
                          ripple
                          color="primary"
                        >
                          {{ agenda_item.meeting.title }}
                        </RouterLink>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ toLocaleDateTimeString(agenda_item.meeting.begin) }} -
                        {{ toLocaleDateTimeString(agenda_item.meeting.concluded) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <RouterLink
                          :to="{ name: 'agendaitem', params: { id: agenda_item.id } }"
                          dense
                          ripple
                          color="primary"
                        >
                          {{
                            $t('business.agendaItem', {
                              title: agenda_item.title,
                            })
                          }}
                        </RouterLink>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </BaseLayout>
</template>

<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import { toLocaleDateTimeString } from '@/filters'
import BusinessSidebar from '@/components/sidebars/BusinessSidebar.vue'

export default {
  name: 'Business',
  components: { BusinessSidebar },
  data() {
    return {
      agenda_items: [],
      business: null,
      id: this.$route.params.id,
    }
  },
  async mounted() {
    await this.fetchBusiness()
    await this.fetchAgendaItems()
  },
  methods: {
    toLocaleDateTimeString,
    async fetchBusiness() {
      try {
        const response = await api.get(createLink('businesses/{id}', { id: this.id }))
        this.business = response.data
      } catch (e) {
        this.notifyError(this.$t('business.error.fetchBusiness'))
      }
    },
    async fetchAgendaItems() {
      try {
        const response = await api.get(createLink('businesses/{id}/agenda_items', { id: this.id }))
        this.agenda_items = response.data
      } catch (e) {
        this.notifyError(this.$t('business.error.fetchAgendaItem'))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.v-list-item {
  min-height: 0 !important;
}
.meta-data-list:deep(.meta-data-item) {
  &:not(:last-child) {
    border-bottom: solid var(--v-bodyBg-base) 1px;
  }
}
</style>

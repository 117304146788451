<template>
  <v-navigation-drawer v-model="versionsSidebar" touchless fixed app right clipped width="376">
    <div class="d-flex flex-column fill-height">
      <DividedList
        data-testid="version-list"
        :items="versionable.versions"
        :empty-message="$t('no_versions_exist')"
        :list-item="VersionListItem"
      >
        <template slot="top">
          <v-subheader class="pl-3">{{ $t('versionsidebar_section_general') }}</v-subheader>
          <div class="px-2">
            <v-btn
              v-if="isInTagesordnungMode"
              depressed
              block
              class="next-item-link mb-2 py-6 body-1"
              :to="nextTagesordungItemLink"
              :disabled="!nextTagesordnungItem.id"
            >
              <div class="d-flex flex-column flex-overflow mr-2">
                <span class="body-2">{{ $t('next_tagesordnung_item') }}</span>
                <span
                  v-if="nextTagesordnungItem.title"
                  data-testid="version-sidebar-next-tagesordnung-item-title"
                  class="text-truncate"
                  >{{ nextTagesordnungItem.title }}</span
                >
                <span v-else>{{ $t('versionsidebar_no_successor_item') }}</span>
              </div>
              <v-icon class="shrink">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              v-else
              depressed
              block
              class="next-item-link mb-2 py-6 body-1"
              :to="nextAgendaItem.link"
              :disabled="!nextAgendaItem.link"
            >
              <div class="d-flex flex-column flex-overflow mr-2 grow align-start">
                <span class="body-2">{{ $t('next_agenda_item') }}</span>
                <span
                  v-if="nextAgendaItem.title"
                  class="text-truncate"
                  data-testid="version-sidebar-next-agenda-item-item-title"
                  >{{ nextAgendaItem.title }}</span
                >
                <span v-else>{{ $t('versionsidebar_no_successor_item') }}</span>
              </div>
              <v-icon class="shrink">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <TagField
            v-if="versionable.hasOwnProperty('tags')"
            class="px-2"
            :tags="versionable.tags"
            :options="tagOptions"
            @save="saveTags"
          />
          <v-subheader class="mt-4 pl-3">{{ $t('versionsidebar_section_versions') }}</v-subheader>
        </template>
      </DividedList>
      <v-spacer />
      <v-btn medium icon @click.stop="toggleVersionsSidebar">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import joinPath from 'path.join'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import VersionListItem from '@/components/listitems/VersionListItem'
import Profile from '@/store/models/profile'

export default {
  name: 'VersionsSidebar',
  props: {
    versionable: {
      type: Object,
      default: () => ({}),
    },
    showNextAgendaItemLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      VersionListItem,
      nextAgendaItem: {
        link: null,
        title: this.$t('nextLinkLoading'),
      },
      nextTagesordnungItem: {
        id: null,
        title: this.$t('nextLinkLoading'),
      },
    }
  },
  computed: {
    ...mapGetters({
      getVersionsSidebar: 'application/getVersionsSidebar',
    }),
    versionsSidebar: {
      set(versionsSidebar) {
        this.setVersionsSidebar({ versionsSidebar })
      },
      get() {
        return this.getVersionsSidebar
      },
    },
    nextTagesordungItemLink() {
      return {
        name: 'agendaitem',
        params: { id: this.nextTagesordnungItem.agenda_item_id },
        query: { tagesordnungitem_id: this.nextTagesordnungItem.id },
      }
    },
    user() {
      return Profile.query().first()
    },
    tagOptions() {
      return this.$store.getters['tags/getTags'](this.versionable.constructor.entity, this.user)
    },
    isInTagesordnungMode() {
      return !!this.$route.query.tagesordnungitem_id
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tagesordnungitem_id) {
          this.fetchNextTagesordnungItemLink()
        } else {
          this.fetchNextAgendaItemLink()
        }
      },
      immediate: true,
    },
  },
  methods: {
    async saveTags(tags) {
      await api.post(`/v1/${this.versionable.constructor.apiPath}/${this.versionable.id}/set_tags`, { tags })
      this.versionable.constructor.fetch(this.versionable.id)
    },
    async fetchNextTagesordnungItemLink() {
      try {
        const response = await api.get(
          createLink('tagesordnung_item/{id}/next_item', { id: this.$route.query.tagesordnungitem_id }),
        )
        this.nextTagesordnungItem = response.data
      } catch (e) {
        this.nextTagesordnungItem = { id: null }
        throw e
      }
    },
    async fetchNextAgendaItemLink() {
      if (!this.showNextAgendaItemLink) {
        this.nextAgendaItem = { link: null }
        return
      }

      try {
        const response = await api.get(createLink(joinPath(this.versionable.idPath, 'next_agenda_item')))
        this.nextAgendaItem = response.data
      } catch (e) {
        this.nextAgendaItem = { link: null }
        throw e
      }
    },
    ...mapActions({
      setVersionsSidebar: 'application/setVersionsSidebar',
      toggleVersionsSidebar: 'application/toggleVersionsSidebar',
    }),
  },
}
</script>

<style lang="scss" scoped>
/* Prevent (inconsistent) thicker border on top of active group item. */
::v-deep .v-list__group--active:before {
  background: none !important;
}
</style>
<style lang="scss">
.next-item-link {
  max-height: none;

  > .v-btn__content {
    justify-content: space-between;
    max-width: 100%;
  }
}
</style>

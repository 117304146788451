<template>
  <div>
    <div class="d-flex flex-wrap">
      <Tag
        v-for="tag in options"
        :key="tag.slug"
        class="mr-4 mt-2"
        :color="tag.color_code"
        :progress="tagProgress(tag)"
        :info-state="infoState(tag)"
        big
        :readonly="tag.readonly"
        @toggle="toggleTag(tag)"
        @info="revealTag(tag)"
      >
        {{ tag.name }}
        <template v-if="showTagCount(tag)">
          {{ tagCount(tag) }}
        </template>
      </Tag>
    </div>
    <v-list v-if="revealedTag" two-line class="py-0">
      <v-list-item v-for="tagged in taggedForSlug(revealedTag.slug)" :key="tagged.username">
        <v-list-item-content class="py-2">
          <v-list-item-title data-testid="tag-field-tagger"
            >{{ tagged.full_name }} ({{ tagged.username }})
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ toLocaleDateTimeString(tagged.created) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import filter from 'lodash/filter'
import Profile from '@/store/models/profile'
import map from 'lodash/map'
import find from 'lodash/find'
import size from 'lodash/size'
import get from 'lodash/get'
import { toLocaleDateTimeString } from '@/filters'
import { INFO_STATE } from './Tag.vue'

export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTags: [],
      revealedTag: null,
    }
  },
  computed: {
    username() {
      return Profile.query().first().username
    },
  },
  watch: {
    tags: {
      handler(tags) {
        this.selectedTags = map(filter(tags, { username: this.username }), 'slug')
      },
      immediate: true,
    },
  },
  methods: {
    toLocaleDateTimeString,
    tagProgress(tag) {
      if (tag.style === 'progress') {
        return (this.tagCountForSlug(tag.slug) / this.taggerCountForSlug(tag.slug)) * 100
      }
      if (tag.readonly) {
        return null
      }
      return this.selectedTags.includes(tag.slug) ? 100 : 0
    },
    tagCount(tag) {
      switch (tag.style) {
        case 'progress':
          return this.$t('tag_field_selectable_tag', {
            selectedCount: this.tagCountForSlug(tag.slug),
            availableCount: this.taggerCountForSlug(tag.slug),
          })
        case 'count':
          return `(${this.tagCountForSlug(tag.slug)})`
        case 'simple':
        default:
          return ''
      }
    },
    toggleTag(tag) {
      const index = this.selectedTags.indexOf(tag.slug)
      if (index > -1) {
        this.selectedTags.splice(index, 1)
      } else {
        this.selectedTags.push(tag.slug)
      }
      this.save(this.selectedTags)
    },
    save(tags) {
      this.$emit('save', tags)
    },
    taggerCountForSlug(slug) {
      return get(find(this.options, { slug }), 'tagger_count', 0)
    },
    tagCountForSlug(slug) {
      return size(filter(this.tags, { slug }))
    },
    showTagCount(tag) {
      return tag.style !== 'simple' && this.taggerCountForSlug(tag.slug) > 1
    },
    taggedForSlug(slug) {
      return filter(this.tags, { slug })
    },
    hasTaggersForSlug(slug) {
      return size(this.taggedForSlug(slug)) > 0
    },
    revealTag(tag) {
      if (!this.hasTaggersForSlug(tag.slug)) {
        return
      }
      if (this.revealedTag && this.revealedTag.slug === tag.slug) {
        this.revealedTag = null
      } else {
        this.revealedTag = tag
      }
    },
    infoState(tag) {
      if (!this.hasTaggersForSlug(tag.slug)) {
        return INFO_STATE.DISABLED
      }

      if (this.revealedTag?.slug === tag.slug && this.hasTaggersForSlug(tag.slug)) {
        return INFO_STATE.ACTIVE
      }

      return INFO_STATE.INACTIVE
    },
  },
}
</script>

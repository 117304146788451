<template>
  <v-list-item ripple exact :to="parentURL">
    <v-list-item-action>
      <v-icon>mdi-chevron-left</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
      <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BackListItem',
  props: {
    parent: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    extraQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    parentURL() {
      const url = this.parent.url
      if (typeof url === 'object') {
        url.query = { ...url.query, ...this.extraQueryParams }
      }
      return url
    },
  },
}
</script>

<template>
  <div
    :class="{
      'tag--big': big,
      'tag--has-info': infoState !== INFO_STATE.HIDDEN,
      'tag--has-progress': progress !== null,
    }"
    :style="cssProperties"
    class="tag d-flex align-content-center"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      class="tag__content rounded-xl text-center font-weight-medium align-content-center overflow-hidden"
      :class="{
        'pr-5 pl-5': big,
        'pr-3 pl-3': !big,
        'cursor-pointer': !readonly,
      }"
      @click="!readonly && $emit('toggle')"
    >
      <slot />
    </div>
    <v-btn
      v-if="infoState !== INFO_STATE.HIDDEN"
      :class="{
        'tag__info--active': infoState === INFO_STATE.ACTIVE,
      }"
      :disabled="infoState === INFO_STATE.DISABLED"
      :value="null"
      class="tag__info rounded-xl"
      data-testid="tag-field-reveal-tag"
      icon
      @click.stop.capture.prevent="$emit('info')"
    >
      <v-icon class="h-auto w-auto" color="grey" size="32px">mdi-information-symbol</v-icon>
    </v-btn>
  </div>
</template>

<script>
export const INFO_STATE = Object.freeze({
  DISABLED: 'disabled',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  HIDDEN: 'hidden',
})

export default {
  name: 'Tag',
  props: {
    color: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      default: null,
    },
    infoState: {
      type: String,
      default: INFO_STATE.HIDDEN,
      validator(value) {
        return Object.values(INFO_STATE).includes(value)
      },
    },
    big: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      INFO_STATE,
    }
  },
  computed: {
    cssProperties() {
      return {
        '--tag-primary-color': this.primaryColor,
        '--tag-secondary-color': this.secondaryColor,
        '--tag-font-color': this.fontColor,
        '--tag-progress': `${this.progress}%`,
      }
    },
    baseColor() {
      return this.hexToHSL(this.color)
    },
    background() {
      if (this.progress === null) {
        return this.primaryColor
      }
      return `linear-gradient(to right, ${this.primaryColor} 0%, ${this.primaryColor} ${this.progress}%, ${this.secondaryColor} ${this.progress}%, ${this.secondaryColor} 100%)`
    },
    primaryColor() {
      const color = this.baseColor
      return this.HSLtoCSSColor(color)
    },
    secondaryColor() {
      const color = this.baseColor
      color.s -= 0.1
      color.l += 0.2
      if (color.l >= 1) {
        return this.HSLtoCSSColor({ h: color.h, s: color.s, l: 0.95 })
      }
      return this.HSLtoCSSColor(color)
    },
    fontColor() {
      const r = parseInt(this.color.substring(1, 3), 16)
      const g = parseInt(this.color.substring(3, 5), 16)
      const b = parseInt(this.color.substring(5, 7), 16)
      const yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? 'black' : 'white'
    },
  },
  methods: {
    HSLtoCSSColor(color) {
      return `hsl(${color.h * 360}, ${color.s * 100}%, ${color.l * 100}%)`
    },
    hexToHSL(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      let r = parseInt(result[1], 16)
      let g = parseInt(result[2], 16)
      let b = parseInt(result[3], 16)
      r /= 255
      g /= 255
      b /= 255
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      let h
      let s
      const l = (max + min) / 2
      if (max === min) {
        h = s = 0 // achromatic
      } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
          case g:
            h = (b - r) / d + 2
            break
          case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
      }
      return {
        h,
        s,
        l,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  letter-spacing: 1px;
  width: 100%;

  &--big {
    height: 30px;
    min-width: 100px;
    width: auto;
  }

  &__content {
    flex: 1;
    background: var(--tag-primary-color);
    color: var(--tag-font-color);
    position: relative;
    z-index: 1;
    white-space: nowrap;
    text-overflow: ellipsis;

    .tag--big & {
      padding-top: 1px;
    }

    .tag--has-info & {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .tag--has-progress & {
      background: var(--tag-secondary-color);

      &:before {
        z-index: -1;
        content: '';
        width: var(--tag-progress);
        height: 100%;
        position: absolute;
        display: block;
        background: var(--tag-primary-color);
        left: 0;
        top: 0;
        transition: 0.2s ease-out;
      }
    }
  }

  &__info {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 100%;
    width: auto;
    background: #eceff1;

    &--active {
      background: #37474f;
    }
  }
}
</style>

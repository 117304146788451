<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters align="center">
        <v-col class="shrink pr-4">
          <v-avatar slot="avatar" size="72" :color="matchColorToName(user.username)">
            <span class="white--text text-h3" v-text="avatarChar" />
          </v-avatar>
        </v-col>
        <v-col>
          <div class="subtitle-1" v-text="fullName" />
          <div class="caption" v-text="user.username" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text class="pa-0">
      <v-list dense class="extra-dense" data-testid="personal-menu">
        <!-- Language -->
        <v-list-item
          v-for="(name, lang_code) in supportedLanguages"
          :key="lang_code"
          class="d-flex justify-space-between"
          :class="{ 'font-weight-bold': lang_code === currentLanguage() }"
          @click="switchLanguage(lang_code)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon v-text="'mdi-web'" />
          </v-list-item-icon>
          <v-list-item-title class="d-flex align-center justify-space-between">
            <span v-text="name" />
            <v-icon v-if="lang_code === currentLanguage()" v-text="'mdi-check'" />
          </v-list-item-title>
        </v-list-item>

        <!-- Logout -->
        <v-divider class="my-2" />
        <v-list-item @click="logout">
          <v-list-item-icon class="justify-center">
            <v-icon v-text="'mdi-power'" />
          </v-list-item-icon>
          <v-list-item-title v-t="'Logout'" />
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import Profile from '@/store/models/profile'
import { matchColorToName } from '@/ui-helpers'
import { supportedLanguages, currentLanguage, switchLanguage } from '@/i18n'
import joinPath from 'path.join'

export default {
  data() {
    return {
      supportedLanguages,
    }
  },
  computed: {
    user() {
      return Profile.query().first()
    },
    fullName() {
      if (this.user.first_name && this.user.last_name) {
        return [this.user.first_name, this.user.last_name].join(' ')
      }
      return null
    },
    avatarChar() {
      return this.user && this.user.username[0].toUpperCase()
    },
  },
  methods: {
    matchColorToName,
    currentLanguage,
    switchLanguage,
    logout() {
      const nextURL = window.location.pathname
      const nextURLQuery = new URLSearchParams({ next: nextURL })

      const loginURL = new URL(window.location.origin)
      loginURL.pathname = joinPath(this.$config.path_prefix, 'auth/logout/')
      loginURL.search = nextURLQuery.toString()

      window.location = loginURL
    },
  },
}
</script>

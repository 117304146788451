<template>
  <DividedList class="sidebar-list" :items="items" :list-item="listItem" :empty-message="emptyMessage">
    <template slot="top">
      <BackListItem v-if="parent" :title="title" :subtitle="subtitle" :parent="parent" />

      <v-divider v-if="parent && items.length" />
    </template>
  </DividedList>
</template>

<script>
import mixin from '@/components/lists/mixin'

export default {
  name: 'DividedSidebarList',
  mixins: [mixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    parent: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
  },
}
</script>

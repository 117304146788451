<template>
  <div>
    <AutocompleteFacet v-model="filter.committee" :label="$t('committeeFacetLabel')" :items="facets.committee" />
    <AutocompleteFacet v-model="filter.meeting" :label="$t('meetingFacetLabel')" :items="facets.meeting" />
    <AutocompleteFacet
      v-model="filter.business_type"
      :label="$t('businessTypeFacetLabel')"
      :items="facets.business_type"
    />
  </div>
</template>

<script>
import dateTimeFilterMixin from '@/components/search/mixins/datetimefilter'

export default {
  name: 'BusinessFacets',
  mixins: [dateTimeFilterMixin],
  props: {
    facets: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
